<template>
  <div id="discordInviteBox"></div>
</template>

<script>

import $ from 'jquery';

export default {
  name: 'DiscordInvite',
  async mounted() {
    const config = {
      inviteCode: 'HpSqVwWPkK',
      title: 'Wild Bunch',
      introText: 'Willkommen auf unserem Discord',
      joinText: 'Beitreten',
      joinedText: 'Joined',
      width: 'auto',
      targetElement: document.getElementById('discordInviteBox'),
      miniMode: false,
      hideIntro: false
    };
    let e = {};
    const url = "https://discordapp.com/invite/" + config.inviteCode;
    const discordCode = config.inviteCode;
    config.miniMode ? config.width = "auto" : "number" == typeof config.width && (config.width = config.width + "px");
    var t = '<div id="discordInvite" style="width: ' + config.width + ';"><h5 id="introText" class="noselect loadHidden">' + config.introText + '</h5><div id="discordData"><div id="serverImg" class="discordLink loadHidden" style="background: rgb(54, 57, 63) repeat scroll 50% 50% / 100% 100% padding-box padding-box;"></div><div id="discordInfo"><div id="serverNameBox" class="discordLink"><span class="noselect" id="serverName">' + config.title + '</span></div><div id="status" class="loadHidden"><div id="statusIndicators" class="noselect"><i id="onlineInd"></i><span id="numOnline">... Online</span><i id="offlineInd"></i><span id="numTotal">... Members</span></div></div></div><button type="button" class="discordLink" id="callToAction"><div id="buttonText" class="noselect">' + config.joinText + "</div></button></div></div>",
        d = '<div id="joinedDiscord">' + config.joinedText + '<svg name="Checkmark" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" id="discordSVG"><g fill="none" fill-rule="evenodd" id="gDiscord"><polyline stroke="currentColor" stroke-width="2" points="3.5 9.5 7 13 15 5" id="discordPolyline"></polyline></g></svg></div>';
    $(config.targetElement).append(t).attr("version", e), $.ajax({
        url: "https://discordapp.com/api/v6/invite/" + discordCode + "?with_counts=true",
        success: function(e) {
            e.code;
            var t = e.approximate_member_count.toLocaleString("en") + " Members",
                o = e.approximate_presence_count.toLocaleString("en") + " Online",
                n = e.guild.name,
                r = "https://cdn.discordapp.com/icons/" + e.guild.id + "/" + e.guild.icon + ".jpg";
            $("#serverName").html(n), $("#serverImg").css("background-image", "url(" + r + ")"), $("#numTotal").html(t), $("#numOnline").html(o), $(".discordLink").click(function() {
                $("#callToAction").html(d).attr("id", "callToAction-clicked"), window.open(url, "_blank")
            }), $(".loadHidden").show(), config.miniMode && $("#offlineInd, #numTotal").hide(), config.hideIntro && $("#introText").hide()
        },
        error: function() {
            $("#discordInvite").css("width", "auto");
            var e = null;
            void 0 !== config.responseJSON ? ($("#buttonText").html(config.responseJSON.message), $("#discordInfo").remove()) : ($("#discordData").remove(), e = !0), e ? $("#introText").html("ERROR: Invalid Data URL.") : $("#introText").html("An error has occurred."), $("#introText").css("margin", 0).show()
        }
    })
  }
}
</script>

<style lang="scss">
  @import '../assets/scss/discord-invite.scss';
</style>
